import styled from "styled-components";
import { RowHeaderHeightType } from "../dataTable";
import { cellHeight, cellDoubleHeight, cellHeightWithSubData } from "../styles";
import { fixedColumnType } from "../types/fixedColumnType";

type FixedColumnProps = {
  columnType?: fixedColumnType;
};
export const FixedColumn = styled.div<FixedColumnProps>`
  display: flex;
  flex-direction: column;
  position: sticky;
  ${(props) => (props.columnType === "total" ? "right: 0" : "left: 0")};
  z-index: 2;

  .scrollbar-spacer {
    height: 8px;
  }
`;

type FixedColumnCellProps = {
  rowHeaderHeight?: RowHeaderHeightType;
};
export const FixedColumnCell = styled.div<FixedColumnCellProps>`
  width: 100%;
  height: ${(props) =>
    props.rowHeaderHeight === "double" ? cellDoubleHeight : cellHeight};
  padding: ${({ theme }) => `0 ${theme.utilities.spacing["space-md"]}`};
  background-color: ${({ theme }) => theme?.colors?.["primary-dark"]};
  color: white;
  white-space: nowrap;
  border-top: 1px solid ${({ theme }) => theme?.colors?.["table-line-light"]};
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .value-row {
    display: flex;
    align-items: center;
  }

  &:first-child {
    text-transform: uppercase;
    letter-spacing: 2px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &.sub-data {
    height: ${cellHeightWithSubData};
  }

  &.main {
    background-color: ${({ theme }) => theme?.colors?.["primary-dark"]};
  }

  &.sub {
    background-color: ${({ theme }) => theme?.colors?.["primary-darker"]};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme?.colors?.["primary-darker"]};
  }

  &:last-child {
    height: calc(${cellHeight} + 8px);

    &.sub-data {
      height: calc(${cellHeightWithSubData} + 8px);
    }
    padding-bottom: 8px;
  }
`;

type DataTableFixedCellProps = {
  stickyBottomTotalColumn?: boolean;
};
export const FixedColumnTotalCell = styled.div<DataTableFixedCellProps>`
  width: 100%;
  height: ${cellHeight};
  padding: ${({ theme }) => `0 ${theme.utilities.spacing["space-md"]}`};
  background-color: ${({ theme }) => theme?.colors?.["primary-dark"]};
  color: white;
  white-space: nowrap;
  border-top: 1px solid ${({ theme }) => theme?.colors?.["table-line-light"]};
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .value-row {
    display: flex;
    align-items: center;
  }

  &:first-child {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &.sub-data {
    height: ${cellHeightWithSubData};
  }

  background-color: ${({ theme }) => theme?.colors?.["ui-total-row"]};

  &:last-child {
    height: calc(${cellHeight} + 8px);

    &.sub-data {
      height: calc(${cellHeightWithSubData} + 8px);
    }
    padding-bottom: 8px;
  }

  ${({ stickyBottomTotalColumn }) =>
    stickyBottomTotalColumn &&
    `
      position: sticky;
      z-index: 2;
      bottom: 0;
    `}
`;

export const SecondaryValue = styled.span`
  font-size: 0.7rem;
`;
