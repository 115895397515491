import styled from "styled-components";
import { RowHeaderHeightType } from "../dataTable";
import {
  cellHeight,
  cellDoubleHeight,
  cellHeightWithSubData,
  scrollableCellWidth,
} from "../styles";

export const TableData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // overflow-x: hidden;

  &.no-scroll {
    width: max-content;
  }

  .scroll-container {
    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: ${({ theme }) =>
        theme?.utilities.borders["border-radius-md"]};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: ${({ theme }) => theme?.colors?.["ui-gray-dark"]};
      opacity: 0.4;
      box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0) inset;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${({ theme }) =>
        theme?.utilities.borders["border-radius-md"]};
      background: ${({ theme }) => theme?.colors?.["scrollbar"]};

      &:hover {
        background: ${({ theme }) => theme?.colors?.["table-line"]};
      }
    }
  }
`;

type TableRowProps = {
  columnWidth?: string;
  rowHeaderHeight?: RowHeaderHeightType;
};
export const TableRow = styled.div<TableRowProps>`
  height: ${(props) =>
    props.rowHeaderHeight === "double" ? cellDoubleHeight : cellHeight};
  display: grid;
  grid-auto-columns: ${(props) => props.columnWidth ?? scrollableCellWidth};
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;

  &.column-headers {
    font-weight: bold;
    position: sticky;
    top: 0;
    background: ${({ theme }) => theme?.colors?.["ui-gray"]};
  }

  &.sub-data {
    height: ${cellHeightWithSubData};
  }

  &:first-child {
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.["table-line"]};
  }
  &:last-child {
    // height: calc(50px + 8px);
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme?.colors?.["ui-gray-dark"]};
  }
`;
type TableTotalRowProps = {
  columnWidth?: string;
  rowHeaderHeight?: RowHeaderHeightType;
  stickyBottomTotalColumn?: boolean;
};
export const TableTotalRow = styled.div<TableTotalRowProps>`
  height: ${cellHeight};
  display: grid;
  grid-auto-columns: ${(props) => props.columnWidth ?? scrollableCellWidth};
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.["table-line"]};
  background-color: ${({ theme }) => theme?.colors?.["ui-total-row-data"]};

  &.column-headers {
    font-weight: bold;
  }

  &.sub-data {
    height: ${cellHeightWithSubData};
  }

  ${({ stickyBottomTotalColumn }) =>
    stickyBottomTotalColumn &&
    `
      &:last-child {
        position: sticky;
        z-index: 1;
        bottom: 0;
      }
    `}
`;

export const TableCell = styled.div`
  width: 100%;
  height: ${cellHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme?.colors?.["text-dark"]};
  text-align: center;
  padding: ${({ theme }) =>
    `${theme.utilities.spacing["space-sm"]} ${theme.utilities.spacing["space-md"]}`};
`;

type TableCellHeaderProps = {
  rowHeaderHeight?: RowHeaderHeightType;
};
export const TableCellHeader = styled.div<TableCellHeaderProps>`
  width: 100%;
  height: ${(props) =>
    props.rowHeaderHeight === "double" ? cellDoubleHeight : cellHeight};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme?.colors?.["text-dark"]};

  padding: ${({ theme }) =>
    `${theme.utilities.spacing["space-sm"]} ${theme.utilities.spacing["space-md"]}`};
`;

export const TitleCell = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  white-space: initial;
  cursor: pointer;
  /* height: ${cellHeight};
   overflow: visible;
  text-overflow: ellipsis;
  word-wrap: break-word; */
`;

export const SortToggleArrow = styled.div`
  display: inline-block;
  margin-left: 0.5rem;

  .fa-sort-down {
    position: relative;
    top: -2px;
  }
  .fa-sort-up {
    position: relative;
    bottom: -4px;
  }
`;

export const SecondaryValue = styled.span`
  font-size: 0.7rem;
`;
