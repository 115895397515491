import styled from "styled-components";

export const Wrapper = styled.div``;
export const Dropdowns = styled.div`
  display: flex;
  white-space: nowrap;
  gap: ${({ theme }) => theme.utilities.spacing["space-sm"]};
  height: 49px;
  margin-bottom: ${({ theme }) => theme.utilities.spacing["space-md"]};
  margin-right: 20px !important;
  padding-right: 20px !important;
`;

export const SearchInput = styled.input`
  appearance: none;
  background-color: ${({ theme }) => theme?.colors?.["ui-gray-lighter"]};
  padding: 0.6rem 2.5rem 0.6rem 1.25rem;
  color: ${({ theme }) => theme?.colors?.["text-default"]};
  font-size: 1.125rem;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  border-radius: ${({ theme }) =>
    theme?.utilities?.borders?.["border-radius-md"]};
  border: 1px solid ${({ theme }) => theme?.colors?.["text-default"]};
  height: 100%;
  width: 25rem;

  &:focus-visible {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    appearance: none;
    color: ${({ theme }) => theme?.colors?.["text-default"]};
    border: 2px solid ${({ theme }) => theme?.colors?.["text-default"]};
  }

  &::placeholder {
    color: ${({ theme }) => theme?.colors?.["text-default"]};
  }
`;
