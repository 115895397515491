import { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import DataTableDataRow from "./dataTableDataRow";
import DataTableTotalRow from "./dataTableTotalRow";
import {
  TableData,
  TableRow,
  TitleCell,
  TableCellHeader,
  SortToggleArrow,
} from "./styles";
import { DataTableRow } from "../types/dataTableRow";
import Tooltip from "../tooltip";
import { DataTableColumnHeader, RowHeaderHeightType } from "../dataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
interface DataTableDataProps {
  rows: DataTableRow[];
  columnHeaders: DataTableColumnHeader[];
  columnWidth?: string;
  rowHeaderHeight?: RowHeaderHeightType;
  noScroll?: boolean;
  totalPosition?: "bottom" | "right";
  scrollOpts?: {
    initialScrollPosition?: "right" | "left";
    keepLatestScrollPosition?: boolean;
  };
  stickyBottomTotalColumn?: boolean;
  enableColumnSorting?: boolean;
  setRows: (rows: DataTableRow[]) => void
}

const DataTableData = ({
  rows,
  columnHeaders,
  columnWidth,
  rowHeaderHeight,
  noScroll,
  totalPosition,
  scrollOpts = {
    initialScrollPosition: "right",
    keepLatestScrollPosition: false,
  },
  stickyBottomTotalColumn = false,
  enableColumnSorting,
  setRows
}: DataTableDataProps) => {
  // const scrollContainerRef = useRef<HTMLElement>(null);
  // const [isGrabbing, setIsGrabbing] = useState(false);
  const [currentSortedColumn, setCurrentSortedColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC" | null>(null);

  // useEffect(() => {
  //   if (scrollContainerRef.current && !noScroll) {
  //     if (!scrollOpts.keepLatestScrollPosition) {
  //       scrollContainerRef.current.scrollLeft =
  //         scrollOpts.initialScrollPosition === "left"
  //           ? 0
  //           : scrollContainerRef.current.scrollWidth;
  //     } else {
  //       if (
  //         scrollOpts.initialScrollPosition === "left" &&
  //         scrollContainerRef.current.scrollLeft === 0
  //       ) {
  //         scrollContainerRef.current.scrollLeft = 0;
  //       }
  //       //TODO Fix keepLatestScrollPosition when positios is right
  //       if (scrollOpts.initialScrollPosition === "right") {
  //         scrollContainerRef.current.scrollLeft =
  //           scrollContainerRef.current.scrollWidth;
  //       }
  //     }
  //   }

  //   //eslint-disable-next-line
  // }, [scrollContainerRef, rows]);
  
  const handleSortColumn = (headerType: string) => {
    if (
      !headerType ||
      !enableColumnSorting ||
      nonSortableColumns.includes(headerType)
    ) return;

    const rowsToSort = [...rows.slice(0, -1)]; // don't sort 'Totals' row

    console.log({headerType, rowsToSort})
    const sortedRows = rowsToSort.sort((a: any, b: any) => {
      const valueB: string | number = b.data.find((m) => m.label === headerType).value;
      const valueA: string | number = a.data.find((m) => m.label === headerType).value;

      const valB = headerType !== 'status' && typeof valueB === 'string' ?  Number(valueB.replace(/[$,%]/g, '')) : valueB;
      const valA = headerType !== 'status' && typeof valueA === 'string' ?  Number(valueA.replace(/[$,%]/g, '')) : valueA;

      const previousSortDirection = sortDirection;

      if (!previousSortDirection || currentSortedColumn !== headerType) {
        setCurrentSortedColumn(headerType);
        setSortDirection("DESC");
        return sort(valA, valB, "DESC");
      }
      else if (previousSortDirection === "DESC") {
        setSortDirection("ASC");
        return sort(valA, valB, "ASC");
      }
      else if (previousSortDirection === "ASC") {
        setSortDirection("DESC");
        return sort(valA, valB, "DESC");
      }
    });

    sortedRows.push(rows[rows.length-1]); // add 'Totals' row back
    setRows(sortedRows);
  };

  const sort = (a: any, b: any, direction: string) => {
    if (typeof a === "string" && typeof b === "string") return sortString(a, b, direction);
    if (typeof a === "number" && typeof b === "number") return sortNumber(a, b, direction);
  }
  
  const sortString = (a: string, b: string, direction: string) => {
    if (direction === "DESC") {return a.toLowerCase().localeCompare(b.toLowerCase())};
    if (direction === "ASC") return b.toLowerCase().localeCompare(a.toLowerCase());	
  }

  const sortNumber = (a: number, b: number, direction: string) => {
    if (direction === "DESC") return b - a;
    if (direction === "ASC") return a - b;
  }

  const nonSortableColumns = ["campaignStartDate", "campaignEndDate", "source"];

  return (
    <TableData className={noScroll && "no-scroll"}>
      {/* <ScrollContainer
        className="scroll-container"
        vertical={false}
        hideScrollbars={false}
        onStartScroll={() => setIsGrabbing(true)}
        onEndScroll={() => setIsGrabbing(false)}
        style={{
          cursor: isGrabbing ? "grabbing" : "grab",
        }}
        innerRef={scrollContainerRef}
      > */}
        <TableRow
          className="column-headers"
          columnWidth={columnWidth}
          rowHeaderHeight={rowHeaderHeight}
        >
          {columnHeaders.map(({ title, label, tooltip }) => (
            <TableCellHeader key={title} rowHeaderHeight={rowHeaderHeight}>
              <TitleCell onClick={() => handleSortColumn(label)}>
                {title}
                {(enableColumnSorting && label && !nonSortableColumns.includes(label)) && (
                  <SortToggleArrow>
                    {currentSortedColumn !== label && (
                      <FontAwesomeIcon
                        icon={(faSort) as IconProp}
                      />
                    )}
                    {currentSortedColumn === label && sortDirection === "DESC" && (
                      <FontAwesomeIcon
                        icon={(faSortDown) as IconProp}
                      />
                    )}
                    {currentSortedColumn === label && sortDirection === "ASC" && (
                      <FontAwesomeIcon
                        icon={(faSortUp) as IconProp}
                      />
                    )}
                  </SortToggleArrow>
                )}
              </TitleCell>
              {tooltip && <Tooltip text={tooltip} />}
            </TableCellHeader>
          ))}
        </TableRow>
        {rows
          .filter((r) => r?.title !== "Totals")
          .map((row, i) => (
            <DataTableDataRow key={i} row={row} columnWidth={columnWidth} />
          ))}
        {totalPosition === "bottom" &&
          rows
            .filter((r) => r.title === "Totals")
            .map((row, i) => (
              <DataTableTotalRow key={i} row={row} columnWidth={columnWidth} stickyBottomTotalColumn={stickyBottomTotalColumn} />
            ))}
      {/* </ScrollContainer> */}
    </TableData>
  );
};

export default DataTableData;
