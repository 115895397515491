import {
  AnalyticsFormatFunction,
  FormatType,
  useAnalyticsFormatting,
} from "hooks/useAnalyticsFormatting";

import {
  DateRange,
  DateRangeBinWidthKey,
  DateRangesAreEqual,
} from "../../utils/dateRange";

import {
  dateRangePresets,
  RangeKey,
} from "../../components/date-range/useDateRange";

import Card from "../../components/card";
import { Dropdowns, Wrapper, SearchInput } from "./styles";
import {
  DataByAnalyticType,
  Filters,
  serializeDateRange,
  toDataByAnalyticTypeArray,
  toDataByBinArray,
} from "hooks/globalContext";
import { useEffect, useRef, useState } from "react";
import { useGlobalAnalytics } from "hooks/useGlobalAnalytics";
import { useBusinessMappings } from "hooks/useBusinessMappings";
import { DataTableRow } from "components/dataTable/types/dataTableRow";
import DataTable from "components/dataTable";
import DataTablePlaceholder from "components/dataTable/dataTablePlaceholder";
import Form from "components/form";
import AreaGraph from "components/areaGraph";
import AreaGraphPlaceholder from "components/areaGraph/areaGraphPlaceholder";

type DataTableRowConfig = {
  value: {
    dataKey: string;
  };
  tooltipText: string;
  secondaryValue?: DataTableRowConfig["value"];
  title?: string;
  formatType?: FormatType;
};

type ConfigDrivenPropsType = {
  key?: string;
  pageFilters: Filters;
  loading: boolean;
  config: {
    filters: Filters; // TODO: Improve this.
    campaigns: {
      default: any;
      values: any[];
      aeFilter: string;
    };
    graph: {
      defaultAnalyticKey: string;
    };
    title: string;
    table: DataTableRowType[];
    hideTotalColumn: boolean;
    enableColumnSorting: boolean;
    initialRangeKey: "30d" | "12w" | "12m";
    shouldInheritPageFilters: boolean;
    colorTheme: string;
  };
};

type DataTableRowType = {
  name: string;
  dataKey: string;
  tooltipText: string;
  secondaryValue?: string;
  title?: string;
  formatType?: FormatType;
};

export const CampaignAreaGraphWithDataTable: React.FC<ConfigDrivenPropsType> = (
  props
) => {
  const [selectedCampaign, setSelectedCampaign] = useState("Campaigns");

  const [dateRange, setDateRange] = useState<DateRange>(
    dateRangePresets[props.config.initialRangeKey].getDateRange
  );

  const [selectedDataKey, setSelectedDataKey] = useState(
    props.config.graph.defaultAnalyticKey
  );

  // const [filters, setFilters] = useState(
  //   props.config.shouldInheritPageFilters
  //     ? props.pageFilters
  //     : [props.config.filters]
  // );

  const [tableData, setTableData] = useState(toDataByAnalyticTypeArray({}));
  const [graphData, setGraphData] = useState(toDataByBinArray({}));
  const [totals, setTotals] = useState<{ [key: string]: number | string }>({});
  const containerRef = useRef<HTMLDivElement>();
  const [containerWidth, setContainerWidth] = useState(900);

  const allDependencies = getAllDependencies(props.config, dateRange);
  const {
    loading: loadingDependencies,
    dependencyData,
    dependencies,
    setDependencies,
    setCanFetch,
  } = useGlobalAnalytics({
    dependencies: allDependencies,
    dateRange,
    // filters, // Add filters
  });

  useEffect(() => {
    const currentDateRangeKey = serializeDateRange(dateRange);
    if (!(props.loading || loadingDependencies)) {
      setTotals(extractTotalsFromDependencies(dependencyData));
      setGraphData(toDataByBinArray(dependencyData[currentDateRangeKey]));
      setTableData(
        toDataByAnalyticTypeArray(dependencyData[currentDateRangeKey])
      );
    }
  }, [
    dependencyData,
    props.loading,
    loadingDependencies,
    dateRange,
    selectedCampaign,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
    //eslint-disable-next-line
  }, [containerRef.current]);

  useEffect(() => {
    if (!props.loading) {
      setCanFetch(true);
    } else {
      setCanFetch(false);
    }
  }, [props.loading, setCanFetch]);

  useEffect(() => {
    if (selectedCampaign) {
      setDependencies(
        dependencies.map((dependency) => {
          return {
            ...dependency,
            filters: {
              [props.config.campaigns.aeFilter]: [selectedCampaign],
            },
          };
        })
      );
    }
  }, [selectedCampaign]);

  const data = graphData.map(({ binName, data }) => ({
    ...data,
    key: binName,
  }));

  /** get headers -- headers are the bin names for this table */
  const mapTerms = useBusinessMappings();
  const format = useAnalyticsFormatting();
  const dataTableColumnHeaders =
    tableData[0]?.binNames.map((binName) => {
      return { title: mapTerms(binName) };
    }) || [];

  const dataTableRows = formatRows(
    tableData,
    props?.config?.table,
    mapTerms,
    format,
    totals
  );

  const tableConfig = props.config;
  const campaigns = [
    // "Campaigns",
    ...new Set(tableConfig?.campaigns?.values.map((campaign) => campaign.key)),
  ];

  const loading = props.loading || loadingDependencies;

  return (
    <Card.BasicCard title={props.config.title}>
      <Wrapper ref={containerRef}>
        <Dropdowns>
          {!loading && (
            <Form.Dropdown
              options={campaigns}
              value={selectedCampaign}
              onChange={(option: any) => {
                setSelectedCampaign(option);
              }}
              mapper={mapTerms}
            ></Form.Dropdown>
          )}
        </Dropdowns>
        <Dropdowns>
          {!loading && (
            <Form.Dropdown
              options={Object.keys(graphData[0]?.data || [])}
              value={selectedDataKey}
              onChange={(newOption: string) => {
                setSelectedDataKey(newOption);
              }}
              mapper={mapTerms}
            />
          )}
        </Dropdowns>
        {!loading ? (
          <AreaGraph
            data={data}
            activeDataKey={selectedDataKey}
            tooltipLabel={mapTerms(selectedDataKey)}
            formatTooltipValue={(value) =>
              format(value, selectedDataKey, { showEmptyData: true })
            }
            graphWidthScale={80}
            width={containerWidth}
            height={400}
          />
        ) : (
          <AreaGraphPlaceholder height={400} />
        )}
        {!loading ? (
          <DataTable
            rows={dataTableRows}
            rowHeader="SOURCE"
            columnHeaders={dataTableColumnHeaders}
            hideTotalColumn={tableConfig.hideTotalColumn}
          />
        ) : (
          <DataTablePlaceholder numRows={props.config.table.length} />
        )}
      </Wrapper>
    </Card.BasicCard>
  );
};

const formatRows = (
  data: DataByAnalyticType[],
  rowConfigs: DataTableRowType[],
  mapper: (string: string) => string = (s) => s,
  format: AnalyticsFormatFunction,
  totals: { [key: string]: number | string }
) => {
  const rowProps = rowConfigs.map((rowConfig) => {
    /** find this rows primary analytic data */
    const values = data.find((row) => row.analyticType === rowConfig.dataKey);
    /** find this rows secondary analytic type if exists */
    const secondaryValues = data.find(
      (row) => row.analyticType === rowConfig?.secondaryValue
    );

    const rowData = (values?.data || []).map((dataPoint, i) => {
      /**calculate this cells value and secondary value if exists */
      return secondaryValues?.data?.[i]
        ? { value: dataPoint, secondaryValue: secondaryValues.data[i] }
        : {
            value: dataPoint,
          };
    });

    const formattedData = rowData.map((row) => {
      let res = {
        value: format(row.value, values?.analyticType),
        secondaryValue: null,
      };
      if (rowConfig.secondaryValue) {
        let formatted = format(row.secondaryValue, rowConfig.secondaryValue, {
          formatType: "percentage",
        });
        res.secondaryValue = formatted === "---" ? null : formatted;
      }

      return res;
    });
    /** construct props for data table row component */
    const rowProps: DataTableRow = {
      title: mapper(rowConfig.dataKey),
      data: formattedData,
      total: getRowTotals(totals, rowConfig, format),
      tooltipText: rowConfig.tooltipText,
    };
    return rowProps;
  });
  return rowProps;
};

const getRowTotals = (
  totals: { [key: string]: number | string },
  rowConfig: DataTableRowType,
  format: AnalyticsFormatFunction
) => {
  return {
    value: format(totals[rowConfig.dataKey], rowConfig.dataKey, {
      showEmptyData: true,
    }),
    secondaryValue: !!rowConfig.secondaryValue
      ? format(totals[rowConfig.secondaryValue], rowConfig.secondaryValue, {
          showEmptyData: true,
          formatType: "percentage",
        })
      : null,
  };
};

/**wrapper for the below generator to clean up the function signature */
const getAllDependencies = (
  config: ConfigDrivenPropsType["config"],
  dateRange: DateRange
) => {
  return [...getAllDependenciesGenerator(config, dateRange)];
};

function* getDependencyGenerator(
  config: ConfigDrivenPropsType["config"],
  dateRange: DateRange
) {
  for (const dependency of config.table) {
    const campaign =
      config.campaigns.values[config.campaigns.default] ||
      config.campaigns.values[0];
    yield {
      analyticType: dependency.dataKey,
      dateRange: dateRange,
      componentFilter: { [config.campaigns.aeFilter]: [campaign] },
    };
    yield {
      analyticType: dependency.dataKey,
      dateRange: { ...dateRange, binWidth: "total" as DateRangeBinWidthKey },
      componentFilter: { [config.campaigns.aeFilter]: [campaign] },
    };
  }
}

/**using a generator to get a nice flat data structure */
function* getAllDependenciesGenerator(
  config: ConfigDrivenPropsType["config"],
  dateRange: DateRange
) {
  const campaigns = [config.campaigns.values[0]];

  for (const campaign of campaigns) {
    for (const dependency of config.table) {
      yield {
        analyticType: dependency.dataKey,
        dateRange: dateRange,
        componentFilter: { [config.campaigns.aeFilter]: [campaign.key] },
      };
      yield {
        analyticType: dependency.dataKey,
        dateRange: { ...dateRange, binWidth: "total" as DateRangeBinWidthKey },
        componentFilter: { [config.campaigns.aeFilter]: [campaign.key] },
      };
    }
  }
}

const extractTotalsFromDependencies = (dependencies) => {
  const totalKey = Object.keys(dependencies).find((key) => key.match("total"));
  const total = dependencies[totalKey];
  if (!total) return {};
  return Object.fromEntries(
    Object.entries(total).map(([key, value]) => {
      return [key, Object.values(value)?.[0] || 0];
    })
  );
};

const Factory = (props: ConfigDrivenPropsType) => ({
  Component: ({ key, loading, pageFilters }) =>
    CampaignAreaGraphWithDataTable({ ...props, key, pageFilters, loading }),
  getInitialDependencies: () => {
    return getAllDependencies(
      props.config,
      dateRangePresets[props.config.initialRangeKey].getDateRange()
    );
  },
});

export default Factory;
