import Form from "components/form";
import {
  Filters,
  serializeDateRange,
  serializeFilters,
} from "hooks/globalContext";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Card from "../../components/card";
import { Dropdowns, Wrapper, SearchInput } from "./styles";
import { useBusinessMappings } from "hooks/useBusinessMappings";
import DateRangeDropDown from "../../components/dateRangeTotal";
import { DateRange, DateRangeBinWidthKey } from "utils/dateRange";
import { dateRangePresetsTotal } from "components/dateRangeTotal/useDateRangeTotal";
import { useGlobalAnalytics } from "hooks/useGlobalAnalytics";
import {
  AnalyticsFormatFunction,
  useAnalyticsFormatting,
} from "hooks/useAnalyticsFormatting";
import DataTable from "components/dataTable";
import DataTablePlaceholder from "components/dataTable/dataTablePlaceholder";
import Button from "components/button/button";
import HideShowColumnsMenu from "components/hideShowColumnsMenu";

type TableConfigProps = {
  columns: {
    name: string;
    dataKey: string[];
    formatType?: string;
    orderable?: boolean;
    filterable?: boolean;
    tooltipText?: string;
  }[];
  rows: {
    keyRows: {
      key: string;
      indexColumnDataKey?: number;
      keyRowType: string;
      aeFilter: string;
      statusLabel?: string;
    }[];
    name?: string;
  };
};

export type ConfigDrivenProps = {
  key?: string;
  pageFilters: Filters;
  loading: boolean;
  config: {
    title: string;
    table: TableConfigProps;
    hideTotalColumn: boolean;
    enableColumnSorting: boolean;
    initialRangeKey: "30d" | "12w" | "12m";
    shouldInheritPageFilters: boolean;
    colorTheme: string;
  };
};

export const ConfigDrivenDataTablePivot: React.FC<ConfigDrivenProps> = (
  props
) => {
  const [dateRange, setDateRange] = useState<DateRange>(
    dateRangePresetsTotal[props.config.initialRangeKey].getDateRange()
  );
  const [dateRangeKey, setDateRangeKey] = useState<string>(
    props.config.initialRangeKey
  );
  const [selectedRowTypeFilter, setSelectedRowTypeFilter] = useState("Source");

  const allDependencies = getAllDependencies(props.config.table, dateRange);

  const [search, setSearch] = useState<string>("");
  const [showColumnsMenu, setShowColumnsMenu] = useState(false);
  const [hiddenColumnIndexes, setHiddenColumnIndexes] = useState<number[]>([]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleHideShowColumns = (hiddenIndexes: number[]) => {
    setHiddenColumnIndexes(hiddenIndexes);
    setShowColumnsMenu(false);
  };

  const {
    loading: loadingDependencies,
    dependencyData,
    dependencies,
    setDependencies,
    setCanFetch,
  } = useGlobalAnalytics({
    dependencies: allDependencies,
    dateRange,
    filtersInsideComponent: true,
  });

  const [tableData, setTableData] = useState({});
  const mapTerms = useBusinessMappings();
  const format = useAnalyticsFormatting();

  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem("campaign-impact-hidden-columns");
    if (storedHiddenColumns) {
      setHiddenColumnIndexes(JSON.parse(storedHiddenColumns));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("campaign-impact-hidden-columns", JSON.stringify(hiddenColumnIndexes));
  }, [hiddenColumnIndexes]);

  useEffect(() => {
    serializeDateRange(dateRange);
    if (!(props.loading || loadingDependencies)) {
      setTableData(extractData(props.config.table, dependencyData));
    }
  }, [
    dependencyData,
    props.loading,
    loadingDependencies,
    dateRange,
    props.config.table,
  ]);
  useEffect(() => {
    if (!props.loading) {
      setCanFetch(true);
    } else {
      setCanFetch(false);
    }
  }, [props.loading, setCanFetch]);

  useEffect(() => {
    if (dateRange) {
      setDependencies(
        dependencies.map((dependency) => {
          return {
            ...dependency,
            dateRange: {
              ...dateRange,
              binWidth: "total" as DateRangeBinWidthKey,
            },
          };
        })
      );
    }
  }, [dateRange]);

  const tableConfig = props.config?.table;
  const rowsHeader = props.config.table.rows.name;

  const defaultColumnHeaders = [
    { title: "Source" },
    // { title: "Campaign ID" },
    { title: "Status" },
    ...new Set(
      tableConfig?.columns.map((keyColumn) => {
        return {
          label: keyColumn.name,
          title: mapTerms(keyColumn.name),
          tooltip: keyColumn.tooltipText,
        };
      })
    ),
  ];
  const columnHeaders = formatColumnHeaders(
    tableConfig,
    mapTerms,
    hiddenColumnIndexes
  );

  const dataTableRows = formatRows(
    tableData,
    tableConfig,
    mapTerms,
    format,
    selectedRowTypeFilter,
    search,
    hiddenColumnIndexes
  );

  const rowsTypes = [
    "Source",
    ...new Set(tableConfig?.rows?.keyRows.map((keyRow) => keyRow.keyRowType)),
  ];

  const CSVFormattedTableRows = formatForCSV(dataTableRows);
  const csvFileName = `campaign-impact-export-${dateRangeKey}.csv`;
  const loading = props.loading || loadingDependencies;

  return (
    <Card.BasicCard title={props.config.title}>
      <Wrapper>
        <Dropdowns>
          <DateRangeDropDown
            initialRangeKey={props.config.initialRangeKey}
            handleDateRangeChange={(range, dateRange) => {
              if (range) setDateRange(range);
              if (dateRange) setDateRangeKey(dateRange.activeRangeKey);
            }}
          />
          <Form.Dropdown
            options={rowsTypes}
            value={selectedRowTypeFilter}
            onChange={(option: any) => {
              setSelectedRowTypeFilter(option);
            }}
            mapper={mapTerms}
          ></Form.Dropdown>
          <SearchInput
            value={search}
            placeholder={"Search"}
            onChange={handleSearch}
          ></SearchInput>
          {!loading && CSVFormattedTableRows && (
            <Button type="link" onClick={() => null}>
              <CSVLink
                data={CSVFormattedTableRows}
                filename={csvFileName}
                enclosingCharacter=""
              >
                Export CSV
              </CSVLink>
            </Button>
          )}
          {!loading && (
            <Button
              type="secondary"
              onClick={() => setShowColumnsMenu(!showColumnsMenu)}
            >
              Hide/Show Columns
            </Button>
          )}
          {!loading && showColumnsMenu && (
            <HideShowColumnsMenu
              defaultColumnHeaders={defaultColumnHeaders}
              hiddenColumnIndexes={hiddenColumnIndexes}
              handleHideShowColumns={handleHideShowColumns}
            />
          )}
        </Dropdowns>
        {!loading ? (
          <DataTable
            rows={dataTableRows}
            columnHeaders={columnHeaders}
            rowHeader={rowsHeader}
            hideTotalColumn={false}
            stickyBottomTotalColumn={true}
            enableColumnSorting={props.config.enableColumnSorting}
            rowHeaderHeight={"double"}
            totalPosition={"bottom"}
            customColorTheme={props.config.colorTheme}
            columnWidth={"210px"}
            scrollOpts={{
              initialScrollPosition: "left",
              keepLatestScrollPosition: true,
            }}
          ></DataTable>
        ) : (
          <DataTablePlaceholder
            numRows={rowsTypes.length}
          ></DataTablePlaceholder>
        )}
      </Wrapper>
    </Card.BasicCard>
  );
};

const formatColumnHeaders = (
  tableConfig,
  mapTerms,
  hiddenIndexes: number[] = []
): any => {
  const columns = [
    { title: "Source", label: "source" },
    // { title: "Campaign Id", label: "campaignId" },
    { title: "Status", label: "status" },
    ...new Set(
      tableConfig?.columns.map((keyColumn) => {
        return {
          label: keyColumn.name,
          title: mapTerms(keyColumn.name),
          tooltip: keyColumn.tooltipText,
        };
      })
    ),
  ];
  let filteredColumns = columns.filter((col, index) => {
    return !hiddenIndexes.includes(index);
  });
  return filteredColumns;
};

const formatRows = (
  data,
  tableConfig: TableConfigProps,
  mapper: (string: string) => string = (s) => s,
  format: AnalyticsFormatFunction,
  selectedRowTypeFilter,
  searchedValue,
  hiddenColumnIndexes: number[] = []
): any => {
  return Object.keys(data)
    .map((row) => {
      const keyRow = tableConfig.rows.keyRows.find(
        (keyRow) => keyRow.key === row
      );
      const keyRowType = keyRow?.keyRowType;
      const statusLabel = keyRow?.statusLabel;

      // Source conditional
      if (
        selectedRowTypeFilter !== "Source" &&
        keyRowType !== selectedRowTypeFilter
      )
        return null;

      // Search conditional always include the totals row
      if (
        searchedValue.length &&
        !search(mapper(String(row)), searchedValue) &&
        row !== "totals"
      )
        return null;

      const keyRowTypeFormated = {
        label: "source",
        value: keyRowType ? mapper(keyRowType) : " ",
      };
      const statusFormated = {
        label: "status",
        value: statusLabel || "",
      };
      // const campaignId = {
      //   label: "campaignId",
      //   value: row !== "totals" ? row : "",
      // };

      const valuesArray = Object.entries(data[row]).map(([key, value]) => {
        return {
          label: String(key),
          value: format(String(value), String(key)),
          secondaryValue: null,
        };
      });

      const valuesWithRowType = [
        ...[keyRowTypeFormated],
        // ...[campaignId],
        ...[statusFormated],
        ...valuesArray,
      ].filter((row, index) => !hiddenColumnIndexes.includes(index));
      return { title: mapper(String(row)), data: valuesWithRowType };
    })
    .filter((row) => row !== null);
};

const search = (value: string, searchedValue: string) => {
  return value.toLocaleLowerCase().includes(searchedValue.toLocaleLowerCase());
};

const mapKeyName = (tableConfig: TableConfigProps, key) => {
  return (
    tableConfig.columns.find((column) => column.dataKey.includes(key))?.name ??
    null
  );
};

const extractTotals = (
  totalsKey: string,
  data,
  tableConfig: TableConfigProps
) => {
  const total = data[totalsKey];
  const totalFormated = Object.entries(total).reduce(
    (accum, [keyMetric, val]) => {
      // Care with Math operations.
      const mappedKeyName = mapKeyName(tableConfig, keyMetric);
      const totalValue = Object.values(val)[0];
      return {
        ...accum,
        [mappedKeyName]: accum[mappedKeyName]
          ? accum[mappedKeyName] + totalValue
          : totalValue,
      };
    },
    {}
  );
  return { totals: totalFormated };
};

const fillMissingMetrics = (values, tableConfig: TableConfigProps) => {
  const allColumns = tableConfig.columns.map((column) => column.name);
  return Object.entries(values).reduce((acc, [key, val]) => {
    const valFilled = allColumns.reduce((accColumn, actualColumn) => {
      return {
        ...accColumn,
        [actualColumn]:
          val[actualColumn] === 0 || val[actualColumn]
            ? val[actualColumn]
            : "?",
      };
    }, {});
    return {
      ...acc,
      [key]: valFilled,
    };
  }, {});
};

const extractValues = (
  totalsKey: string,
  data,
  tableConfig: TableConfigProps
) => {
  const values = Object.entries(data).reduce((acc, [key, value]) => {
    if (key === totalsKey) return acc;
    const keyFormated = key.split(":")[1];
    const valueFormated = Object.entries(value).reduce(
      (accum, [keyMetric, val]) => {
        return {
          ...accum,
          [mapKeyName(tableConfig, keyMetric)]: Object.values(val)[0],
        };
      },
      {}
    );
    return { ...acc, [keyFormated]: valueFormated };
  }, {});
  const valuesWithMissingMetrics = fillMissingMetrics(values, tableConfig);
  return valuesWithMissingMetrics;
};

const extractData = (tableConfig: TableConfigProps, dependencyData) => {
  const keys = Object.keys(dependencyData).find((key) => key.match("total"));
  const data = dependencyData[keys];
  if (!data) return {};
  const totalsKey = serializeFilters(filtersTotal(tableConfig));
  const values = extractValues(totalsKey, data, tableConfig);
  const totals = extractTotals(totalsKey, data, tableConfig);
  return { ...values, ...totals };
};

const getAllDependencies = (table: TableConfigProps, dateRange: DateRange) => {
  return [...getAllDependenciesGenerator(table, dateRange)];
};

const filtersTotal = (table: TableConfigProps) => {
  return table.rows.keyRows.reduce((acc, obj) => {
    return {
      ...acc,
      [obj.aeFilter]: acc[obj.aeFilter]
        ? acc[obj.aeFilter].concat([obj.key])
        : [].concat([obj.key]),
    };
  }, {});
};

const formatForCSV = (dataTableRows: any) => {
  if (!dataTableRows.length) return;

  const dataAsObject = dataTableRows.map((row: any) => {
    return row.data.reduce((acc, curr) => {
      acc['title'] = row.title;
      if (curr.label) {
        acc[curr.label] = curr.value.replace(/,/g, ""); // remove commas
      } else if (curr.value === 'Google') {
        acc['source'] = 'Google';
      }
      return acc;
    }, {});
  });
  return dataAsObject;
};

function* getAllDependenciesGenerator(
  table: TableConfigProps,
  dateRange: DateRange
) {
  const totalFilter = filtersTotal(table);

  for (const column of table.columns) {
    for (const keyRow of table.rows.keyRows) {
      const dependency = keyRow.indexColumnDataKey
        ? column.dataKey[keyRow.indexColumnDataKey]
        : column.dataKey[0];
      if (!dependency) continue;
      yield {
        analyticType: dependency,
        dateRange: { ...dateRange, binWidth: "total" as DateRangeBinWidthKey },
        componentFilter: { [keyRow.aeFilter]: [keyRow.key] },
      };
    }
    for (const dataKey of column.dataKey) {
      if (!dataKey) continue;
      yield {
        analyticType: dataKey,
        dateRange: { ...dateRange, binWidth: "total" as DateRangeBinWidthKey },
        componentFilter: totalFilter,
      };
    }
  }
}

const Factory = (props: ConfigDrivenProps) => ({
  Component: ({ key, loading, pageFilters }) =>
    ConfigDrivenDataTablePivot({ ...props, key, pageFilters, loading }),
  getInitialDependencies: () => {
    return getAllDependencies(
      props.config.table,
      dateRangePresetsTotal[props.config.initialRangeKey].getDateRange()
    );
  },
});

export default Factory;
